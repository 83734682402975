import React from "react";
import PropTypes from "prop-types";

import Hero from "../components/Templates/Hero";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Sections from "../components/Sections/Sections";
import SectionBreadcrumb from "../components/Sections/NewSectionBreadcrumb";
import SectionProductDescription from "../components/Sections/SectionProductDescription";

const HomeProduct = (props) => {
  const { pageContext, blok } = props;
  let doc;
  if (pageContext) {
    doc = JSON.parse(pageContext.story.content);
  } else {
    doc = blok;
  }

  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
    image: doc.hero,
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />

      <React.Fragment>
        <Hero
          title={doc.hero_title || doc.title}
          body={doc.body}
          logo={doc.logo?.filename}
          background={doc.hero?.filename}
          colour={doc.colour}
          video={doc.video_hero && doc.video_hero}
          fixed
        />

        <SectionBreadcrumb
          index={0}
          doc={{
            colour: {
              text: "black",
              background: "white",
            },
          }}
        />

        {doc.sections ? (
          <Sections items={doc.sections} masterData={doc} notfirst={true} template="home_product" />
        ) : (
          <SectionProductDescription doc={doc} masterData={doc} />
        )}
      </React.Fragment>
    </Layout>
  );
};

export default HomeProduct;

HomeProduct.propTypes = {
  pageContext: PropTypes.object,
  blok: PropTypes.object,
};

HomeProduct.defaultProps = {
  pageContext: null,
  blok: null,
};
